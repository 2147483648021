import React from 'react';
import { AiOutlineWhatsApp } from 'react-icons/ai';
import {FiFacebook, FiLinkedin } from 'react-icons/fi';

import { SocialIcons } from '../Header/HeaderStyles';
import { CompanyContainer, FooterWrapper, LinkColumn, LinkItem, LinkList, LinkTitle, Slogan, SocialContainer, SocialIconsContainer } from './FooterStyles';

function Footer() {
  return ( 
    <FooterWrapper>
      <LinkList>
        <LinkColumn>
          <LinkTitle>Call</LinkTitle>
          <LinkItem href='tel:+447459367632'>+447459367632</LinkItem>
        </LinkColumn>

        <LinkColumn>
          <LinkTitle>Email</LinkTitle>
          <LinkItem href='mailto:grandbico81@gmail.com'>grandbico81@gmail.com</LinkItem>
        </LinkColumn>
      </LinkList>
      <SocialIconsContainer>
        <CompanyContainer>
          <Slogan>The Digital Tradition</Slogan>
        </CompanyContainer>
        <SocialContainer>
          <SocialIcons href='https://www.facebook.com/grandbico/'>
            <FiFacebook size="1.2rem" />
          </SocialIcons>
          <SocialIcons href='https://www.linkedin.com/in/bernard-kouassi-471b07125/'>
            <FiLinkedin size="1.2rem" />
          </SocialIcons>
          <SocialIcons href='https://api.whatsapp.com/send?phone=+447459367632'>
            <AiOutlineWhatsApp size="1.2rem" />
          </SocialIcons>
        </SocialContainer>
      </SocialIconsContainer>
    </FooterWrapper>
  );
}

export default Footer;
