import "../translations/i18n";
import i18next from "i18next";

window.addEventListener('load', () => {
    var defaultLangue = document.getElementById('choixlangue');
    defaultLangue.innerText = 'en';

    document.getElementById('fr').addEventListener('click', franceChange);
    async function franceChange(e){
        e.preventDefault();
        
        var choixFrancais = 'fr';
        var langueStr = document.getElementById('choixlangue');
        langueStr.innerText = choixFrancais;
        i18next.changeLanguage(choixFrancais);
        
    };

    document.getElementById('pt').addEventListener('click', portugalChange);
    async function portugalChange(e){
        e.preventDefault();
        
        var choixFrancais = 'pt';
        var langueStr = document.getElementById('choixlangue');
        langueStr.innerText = choixFrancais;
        i18next.changeLanguage(choixFrancais);
        
    };

    document.getElementById('en').addEventListener('click', angleterreChange);
    async function angleterreChange(e){
        e.preventDefault();
        
        var choixFrancais = 'en';
        var langueStr = document.getElementById('choixlangue');
        langueStr.innerText = choixFrancais;
        i18next.changeLanguage(choixFrancais);
        
    };

    document.getElementById('it').addEventListener('click', italieChange);
    async function italieChange(e){
        e.preventDefault();
        
        var choixFrancais = 'it';
        var langueStr = document.getElementById('choixlangue');
        langueStr.innerText = choixFrancais;
        i18next.changeLanguage(choixFrancais);
        
    };
});