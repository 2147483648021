import styled from 'styled-components';

export const TranslateContainer = styled.div`
  width: 100%;
  padding: 0rem;
  display: flex;
  justify-content: space-around;
  margin: 1.5rem 0;
`;

export const LeftSection = styled.div`
  width: 100%;
  @media screen and (max-width: 640px) {
    width: 80%;
    display: flex;
    flex-direction: column;

    margin: 0 auto;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: column;

    margin: 0 auto;
  }
`;

export const ButtonLinks = styled.button`
  color:#d4c0c0;
  fontSize: 0.8rem;
  padding:1rem 1.5rem;
  background: #6b3030;
  border-radius: 15px;
  transition: 0.5s;
  @media screen and (max-width: 640px){
    width:100px;
    padding:10px;
  }
  @media screen and (max-width: 768px){
    width:100px;
    padding:10px;
  }
`;
