import React from 'react';
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
import '../../translations/pouchtranslation';

import { Section, SectionDivider, SectionTitle } from '../../styles/GlobalComponents';
import { Box, Boxes, BoxNum, BoxText } from './AcomplishmentsStyles';


/*const data = [
  { number: 5, text: {t("accomplishmentstock")}},
  { number: 2, text: 'Farming Projects', },
  { number: 6, text: 'Educational Projects', },
  { number: 3, text: 'E-Commerce Projects', }
];*/

function Acomplishments() {
  const {t} = useTranslation();
  const data = [
    { number: 5, text: t("accomplishmentstock"), },
    { number: 2, text: t("accomplishmentfarming"), },
    { number: 6, text: t("accomplishmenteducational"), },
    { number: 3, text: t("accomplishmentecommerce"), }
  ];
  return ( 
    <Section>
      <SectionTitle>{t("accomplishmenttitle")}</SectionTitle>
      <Boxes>
        {data.map((card, index) => (
          <Box key={index}>
            <BoxNum>{card.number}+</BoxNum>
            <BoxText>{card.text}</BoxText>
          </Box>
        ))}
      </Boxes>
      <SectionDivider/>
    </Section>
  );
}

export default Acomplishments;
