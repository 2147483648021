//import Link from 'next/link';
import React from 'react';
import { AiOutlineWhatsApp } from 'react-icons/ai';
import {FiFacebook, FiLinkedin } from 'react-icons/fi';
import { DiCssdeck } from 'react-icons/di';

import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
import '../../translations/pouchtranslation';

import { Container, Div1, Div2, LiStyle, Div3, NavLink, SocialIcons, Span } from './HeaderStyles';

function Header() {
  const {t} = useTranslation();
  return ( 
    <Container>
      <label style={{display:"none"}} id="choixlangue">en</label>
      <Div1>
        <a style={{display:"flex", alignItems:"center", color:"white", textDecoration:"none", marginBottom:"20px"}} href='/'>
          <DiCssdeck size="2rem" /><Span>Portfolio</Span>
        </a>
      </Div1>
      <Div2>
        <LiStyle>
          <NavLink  href="#projects">{t("projet")}</NavLink>
        </LiStyle>
        <LiStyle>
          <NavLink  href="#tech">{t("technologie")}</NavLink>
        </LiStyle>
        <LiStyle>
          <NavLink  href="#about">{t("apropos")}</NavLink>
        </LiStyle>
      </Div2>
      <Div3>
        <SocialIcons href='https://www.facebook.com/grandbico/'>
          <FiFacebook size="1.2rem" />
        </SocialIcons>
        <SocialIcons href='https://www.linkedin.com/in/bernard-kouassi-471b07125/'>
          <FiLinkedin size="1.2rem" />
        </SocialIcons>
        <SocialIcons href='https://api.whatsapp.com/send?phone=+447459367632'>
          <AiOutlineWhatsApp size="1.2rem" />
        </SocialIcons>
      </Div3>
    </Container>
  );
}

export default Header;
