export const TRANSLATIONS_EN = {
    francais:"French",
    anglais:"English",
    portugais:"Portuguese",
    italien:"Italian",

    //Header section
    projet:"Projects",
    technologie:"Technologies",
    apropos:"About",

    //Hero section
    herotexte_1:"I am a trusted PWA web and mobile app Developer to convert your business ideas into hybrid and cross-platform mobile experiences. I offer innovative solutions and explore different markets and segments that let you target your mobile audience. ",
    herotexte_2:"Full-stack developer as well, I focus all my developement skills on app's back-end to interact with the front-end already implemented or designed by my own if needed.",
    herolireplus:"Read More",
    heroliremoins:"Read Less",

    //Read More section
    lireplus:"The reason why my choice is on the PWA, although my skills are not limited exclusively to this new technology is due to the fact that it allows the user to interact and perform actions even in the event of loss of the internet network. This is extremely beneficial for app users in developing countries where mobile network malfunctions are commonplace. PWA is the technology of the future for these scenarios.",

    //Projects section
    titlelive: 'CouchDB Workbench',
    descriptionlive: "The purpose of this project is to help technical or non-technical users querying and managing data in CouchDB in the same way they do with MySQL Workbench.",
    moredescriptionlive:"Couchbench is a progressive web app. It provides a basic interface to the majority of functionality including the ability to create, update, delete, view and design documents, access to configuration settings and an interface to initiate replication. Couchbench is an alternative application to Fauxton as a simpler and more intuitive basic interface for developers using CouchDB technologies. It also allows non-developers to facilitate actions in processing their public and private survey activities. However, mainstream Fauxton users will find that this app's UI is 80% similar to fauxton's. Just a way to quickly become familiar with it. The app includes documentation on how to use it.",
    stacklive:"Stack",
    tagscouchlive:"CouchDB",
    tagspouchlive:"PouchDB",
    tagsreactlive:"React",
    demolive:"Demo",
    installive:"Install",

    titledesign: 'Rishta Users',
    descriptiondesign: "Rishta is an intelligent smart muslim dating progressive web app. It has been built based on the customer's needs and provides ",
    descriptiondesignmore_1:"a basic interface using CouchDB technologies as well. The main goal of Rishta is to connect milions of muslim living in Uk to get married throught this app helping them them to find their soulmate or best friendship, living or doing things together for long time. Just for those that aware of muslim custom dealing with wedding process, Rishta help them along all the wedding process.A friendly realtime matchmaker that gives an idea of how much change a single can match his soulmate based on his needs.",
    stackdesign:"Stack",
    tagsworddesign:" MS Word ",
    readmoredesign:"Read More",
    downloaddesign:"Download",

    titlediagram: 'Rishta Admin',
    descriptiondiagram: "An abstract admin system's overall outline that provides a complete view of how rishta will be managed by his owner. This template should completed soon.",
    descriptiondiagram_1:"However, this admin app is private used. It is obvious that every business needs to be amanged consistently and efficently that engage all businessmen to get if they really need their business growing. Rishta Admin is built using the same progressive app and couchDB technologies. ",
    stackdiagram:"Stack",
    tagsdrawiodiagram:"Draw.io ",
    tagsterratrustdiagram:"Terrastruct ",
    showdiagram:"Show",
    downloaddiagram:"Download",

    titleux: 'Future Projects',
    descriptionux: "Ideas for future projects abound and are always full for those who are passionate about developing new applications and software for needy populations.",
    descriptionux_1:"1. A platform for interconnection and exchange of sellers and buyers from 15 different countries of the West African sub-region for a population estimated at more than 350 million inhabitants where the marketing and exchange systems of various products have stagnated since 1960.",
    descriptionux_2:"2. Integrated system of precision farming will be a set of techniques and practices rationally limiting the use of inputs and water on small and medium agricultural plots. This system will take into account the heterogeneity of the plots to adjust quantitatively, in time and space, inputs and water to improve the economic, agronomic and environmental balance of small and medium-sized farms. It will aim to adapt the farmer's intervention on the plot by using data collected on sheets provided and duly completed, based on simple observations and tests carried out in the field. This data will then be processed and stored on a centralized database server to allow the farmer to view and analyze the operations carried out or to be carried out on his farm. The system will aim to adopt a more farmer-centric approach by providing an agricultural environment suited to farmers' preferences. The end goal was to be achieved by allowing farmers to plan their business, their farming project, the crop to grow and the market where the price is better.",
    stackux:"Stack",
    tagsfigma:"Figma ",
    tagsadobe:"Adobe ",
    showux:"Show",
    downloadux:"Download",

    titlepapers: 'Papers',
    descriptionpapers: "Why Precision Farming App important in Third World Countries?",
    descriptionpapers_1:"Precision farming makes it possible to better understand and analyse the physiological needs of crops and develop decision support tools for farmers. Farmers can use it to characterise plants more easily and quickly by reducing labour on often tedious tasks. A software developed for this purpose will use the massive data collected or generated to develop models and improve the efficiency of precision farming.",
    descriptionpapers_2:"For professional farming organisations, that is a way to provide more accurate advice by producing plot modulation maps for various planting, processing and fertilisation operations to help farmers interpreting their data. Questions of the veracity of those data is an issue due to a lack of regulation. In the context of agroecology, precision farming is positioned in a technological approach requiring expensive hardware and software on the farm, and collective infrastructures for which it is difficult to assess the investment and operating costs (GPS positioning satellites, centralised servers for massive data processing). The question of its efficiency beyond large farms remains.",
    descriptionpapers_3:"An Integrated Precision Farming System (IPFS), will expand the boundaries of the management and development of small and medium-sized food crop farms in third world countries by providing software tools to support and improve market gardening activities.",
    descriptionpapers_4:"Extensive research on many software in precision farming currently on the market highlight that those systems apart from their extremely expensive price, are complex from the point of view of the mechanism and technologies used in deployment. Interaction procedures and user interfaces use more technical words that a simple farmer could not know even understand their meanings. Indeed, the main goal of an IPFS is to simplify the use of precision farming software in its basic form, including the same features and aspects of a complex software system. Otherwise, what would be the real purpose of software development? Simplify what is complex to make everyday life easy and not the opposite.",
    descriptionpapers_5:"IPFS is a set of techniques and practices that rationally limit the use of inputs and water in small and medium-sized agricultural plots. It considers the heterogeneity of the plots to adjust inputs and water quantitatively, over time and space to improve the economic, agronomic and environmental balance of small and medium-sized farms. It aims to adapt the farmer’s intervention on the plot by using data collected on sheets provided and duly completed, based on simple observations and tests carried out in the field. Those data are then processed and stored on a centralised database server to allow the farmer to visualise and analyse the operations performed or to be performed on his farm. IPFS aims to adopt a more farmer-centred approach by providing an agricultural environment that is tailored to farmer’s preferences. The final objective will be achieved by allowing farmers to plan their business, farming project, the crop to be developed and the market where the price is better.",
    descriptionpapers_6:"Furthermore, IPFS aims to encourage and assist farmers to manage their farm more effectively on a day basis by acting as a single access point for all their agricultural resource needs. As such, the inclusion of data file management systems such as CouchDb or MySQL server will allow them to add, edit, delete and access their agricultural resources. In addition, integrated tools such as a calendar planner would make it easier to organise farmer by informing them of important upcoming events and tasks. Finally, it aims to take advantage of the fact that a large majority (90%) of the target population of farmers in sub-Saharan Africa own their mobile phones.",
    descriptionpapers_7:"This will make the system accessible online and offline, optimised on desktops, laptops, tablets and mobile phones by adopting reactive design patterns. In addition, the inclusion of mobile phone specific features and connectivity will aim to seamlessly integrate IPFS into farmer’s lives and effectively support their progress in agriculture regardless of their level of education.",
    stackpapers:"Stack",
    tagspapers:"MS Word ",
    showpapers:"Show",
    downloadpapers:"Download",

    //Technologies section
    technologies:'Technologies',
    texttechnology:"I've worked with a range a technologies in the web and app development world following the three steps in software project. Design, coding and Development...",
    experiencetechnology:"Experience with",

    //Timeline section
    timelineabout:"About",
    timelinetext:"My name is Kouassi Bernard and I am absolutely convinced that patience is a golden path. When I left Côte d'Ivoire for Europe in 1992, my main goal was to get a degree in computer science, to create software and applications to help people and activities of all kinds in the faster and more efficient accomplishment of their daily tasks. Since then, I have developed a series of personal mobile applications in several fields and sectors of activity for the well-being of Third World populations. Applications capable of operating Offline. In short, all that I need is that the mobile phone now becomes a portable office. This is what I call: The digital tradition.",

    //Personal Accomplishments section
    accomplishmenttitle:"Personal Accomplishments",
    accomplishmentstock:"Stock Management Projects",
    accomplishmentfarming:"Farming Projects",
    accomplishmenteducational:"Educational Projects",
    accomplishmentecommerce:"E-Commerce Projects",
};