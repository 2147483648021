export const TRANSLATIONS_IT = {
    francais:"Francese",
    anglais:"Inglese",
    portugais:"Portoghese",
    italien:"Italiano",

    //Header section
    projet:"Progetti",
    technologie:"Tecnologie",
    apropos:"Riguardo",

    //Hero section
    herotexte_1:"Sono uno sviluppatore di app mobili e web PWA affidabile per convertire le tue idee di business in esperienze mobili ibride e multipiattaforma. Offro soluzioni innovative ed esploro diversi mercati e segmenti che ti consentono di rivolgerti al tuo pubblico mobile.",
    herotexte_2:"Anche sviluppatore full-stack, concentro tutte le mie capacità di sviluppo sul back-end dell'app per interagire con il front-end già implementato o progettato da me, se necessario.",
    herolireplus:"Leggi più",
    heroliremoins:"Leggi meno",

    //Read More section
    lireplus:"Il motivo per cui la mia scelta è sulla PWA, anche se le mie competenze non si limitano esclusivamente a questa nuova tecnologia è dovuto al fatto che permette all'utente di interagire ed eseguire azioni anche in caso di perdita della rete internet. Ciò è estremamente vantaggioso per gli utenti di app nei paesi in via di sviluppo dove i malfunzionamenti della rete mobile sono all'ordine del giorno. PWA è la tecnologia del futuro per questi scenari.",

    //Projects section
    titlelive: 'CouchDB Workbench',
    descriptionlive: "Lo scopo di questo progetto è aiutare gli utenti tecnici o non tecnici a interrogare e gestire i dati in CouchDB nello stesso modo in cui fanno con MySQL Workbench.",
    moredescriptionlive:"Couchbench è un'app web progressiva. Fornisce un'interfaccia di base per la maggior parte delle funzionalità, inclusa la possibilità di creare, aggiornare, eliminare, visualizzare e progettare documenti, accedere alle impostazioni di configurazione e un'interfaccia per avviare la replica. Couchbench è un'applicazione alternativa a Fauxton come interfaccia di base più semplice e intuitiva per gli sviluppatori che utilizzano le tecnologie CouchDB. Consente inoltre ai non sviluppatori di facilitare le azioni nell'elaborazione delle loro attività di indagine pubbliche e private. Tuttavia, gli utenti tradizionali di Fauxton scopriranno che l'interfaccia utente di questa app è simile all'80% a quella di Fauxton. Solo un modo per familiarizzare rapidamente con esso. L'app include la documentazione su come utilizzarla.",
    stack:"Pila",
    tagscouchlive:"CouchDB  ",
    tagspouchlive:"PouchDB  ",
    tagsreactlive:"React",
    demolive:"Demo",
    installive:"Install",

    titledesign: 'Rishta Users',
    descriptiondesign: "Rishta è un'app web progressista intelligente per incontri musulmani intelligenti. È stato costruito in base alle esigenze del cliente ",
    descriptiondesignmore_1:"e fornisce un'interfaccia di base che utilizza anche le tecnologie CouchDB. L'obiettivo principale di Rishta è connettere milioni di musulmani che vivono nel Regno Unito per sposarsi attraverso questa app aiutandoli a trovare la loro anima gemella o la migliore amicizia, vivendo o facendo cose insieme per molto tempo. Solo per coloro che sono consapevoli dell'usanza musulmana che si occupa del processo del matrimonio, Rishta li aiuta lungo tutto il processo del matrimonio. Un amichevole sensale in tempo reale che dà un'idea di quanto cambiamento può corrispondere alla sua anima gemella in base alle sue esigenze.",
    stackdesign:"Pila",
    tagsworddesign:" MS Word ",
    readmoredesign:"Leggi più",
    downloaddesign:"Scaricare",

    titlediagram: 'Rishta Admin',
    descriptiondiagram: "Schema generale di un sistema di amministrazione astratto che fornisce una visione completa di come rishta sarà gestito dal suo proprietario.",
    descriptiondiagram_1:"Tuttavia, questa app di amministrazione è utilizzata privatamente. È ovvio che ogni azienda deve essere gestita in modo coerente ed efficiente che coinvolga tutti gli uomini d'affari per ottenere se hanno davvero bisogno che la loro attività cresca. Rishta Admin è realizzato utilizzando la stessa app progressiva e le stesse tecnologie couchDB.",
    stackdiagram:"Pila",
    tagsdrawiodiagram:"Draw.io ",
    tagsterratrustdiagram:"Terrastruct ",
    showdiagram:"Mostrare",
    downloaddiagram:"Scaricare",

    titleux: 'Progetti Futuri',
    descriptionux: "Le idee per progetti futuri abbondano e sono sempre piene per gli appassionati dello sviluppo di nuove applicazioni e software.",
    descriptionux_1:"1. Una piattaforma per l'interconnessione e lo scambio di venditori e acquirenti provenienti da 15 diversi paesi della subregione dell'Africa occidentale per una popolazione stimata in oltre 350 milioni di abitanti dove i sistemi di commercializzazione e scambio di vari prodotti sono stagnanti dal 1960.",
    descriptionux_2:"2. Il sistema integrato di agricoltura di precisione sarà un insieme di tecniche e pratiche che limitano razionalmente l'uso degli input e dell'acqua su appezzamenti agricoli di piccole e medie dimensioni. Tale sistema terrà conto dell'eterogeneità degli appezzamenti per adeguare quantitativamente, nel tempo e nello spazio, apporti e acqua per migliorare gli equilibri economici, agronomici e ambientali delle piccole e medie aziende. Mirerà ad adattare l'intervento dell'agricoltore sull'appezzamento utilizzando i dati raccolti su schede fornite e debitamente compilate, sulla base di semplici osservazioni e prove effettuate sul campo. Tali dati verranno poi elaborati e archiviati su un server database centralizzato per consentire all'agricoltore di visualizzare e analizzare le operazioni svolte o da svolgere nella propria azienda. Il sistema mirerà ad adottare un approccio più incentrato sull'agricoltore fornendo un ambiente agricolo adatto alle preferenze degli agricoltori. L'obiettivo finale doveva essere raggiunto consentendo agli agricoltori di pianificare la loro attività, il loro progetto agricolo, il raccolto da coltivare e il mercato dove il prezzo è migliore.",
    stackux:"Pila",
    tagsfigma:"Figma ",
    tagsadobe:"Adobe ",
    showux:"Mostrare",
    downloadux:"Scaricare",

    titlepapers: 'Documenti',
    descriptionpapers: "Perché un'applicazione di Precision Farming importante nei Paesi del Terzo Mondo?",
    descriptionpapers_1:"L'agricoltura di precisione consente di comprendere e analizzare meglio i bisogni fisiologici delle colture e di sviluppare strumenti di supporto alle decisioni per gli agricoltori. Gli agricoltori possono utilizzarlo per caratterizzare le piante in modo più semplice e rapido, riducendo il lavoro su compiti spesso noiosi. Un software sviluppato per questo scopo utilizzerà gli enormi dati raccolti o generati per sviluppare modelli e migliorare l'efficienza dell'agricoltura di precisione.",
    descriptionpapers_2:"Per le organizzazioni agricole professionali, questo è un modo per fornire consigli più accurati producendo mappe di modulazione della trama per varie operazioni di semina, lavorazione e fertilizzazione per aiutare gli agricoltori a interpretare i propri dati. Le questioni sulla veridicità di tali dati sono un problema dovuto alla mancanza di regolamentazione. Nel contesto dell'agroecologia, l'agricoltura di precisione si posiziona in un approccio tecnologico che richiede hardware e software costosi in azienda, e infrastrutture collettive per le quali è difficile valutare i costi di investimento e operativi (satelliti di posizionamento GPS, server centralizzati per l'elaborazione massiva dei dati). Rimane il problema della sua efficienza al di là delle grandi aziende agricole.",
    descriptionpapers_3:"Un sistema integrato di agricoltura di precisione (IPFS) amplierà i confini della gestione e dello sviluppo delle piccole e medie aziende agricole nei paesi del terzo mondo fornendo strumenti software per supportare e migliorare le attività di orticoltura.",
    descriptionpapers_4:"Ricerche approfondite su molti software per l'agricoltura di precisione attualmente sul mercato evidenziano che tali sistemi, a parte il loro prezzo estremamente costoso, sono complessi dal punto di vista del meccanismo e delle tecnologie utilizzate nell'implementazione. Le procedure di interazione e le interfacce utente utilizzano parole più tecniche che un semplice agricoltore non potrebbe conoscere nemmeno capirne il significato. In effetti, l'obiettivo principale di un IPFS è semplificare l'uso del software di agricoltura di precisione nella sua forma di base, includendo le stesse caratteristiche e aspetti di un sistema software complesso. Altrimenti, quale sarebbe il vero scopo dello sviluppo del software? Semplifica ciò che è complesso per rendere facile la vita di tutti i giorni e non il contrario.",
    descriptionpapers_5:"IPFS è un insieme di tecniche e pratiche che limitano razionalmente l'uso di input e acqua negli appezzamenti agricoli di piccole e medie dimensioni. Considera l'eterogeneità degli appezzamenti per adeguare quantitativamente, nel tempo e nello spazio, apporti e acqua per migliorare gli equilibri economici, agronomici e ambientali delle piccole e medie aziende. Ha lo scopo di adattare l'intervento dell'agricoltore sull'appezzamento utilizzando i dati raccolti su schede fornite e debitamente compilate, sulla base di semplici osservazioni e prove effettuate sul campo. Tali dati vengono quindi elaborati e archiviati su un server database centralizzato per consentire all'agricoltore di visualizzare e analizzare le operazioni eseguite o da eseguire nella propria azienda. IPFS mira ad adottare un approccio più incentrato sull'agricoltore fornendo un ambiente agricolo su misura per le preferenze dell'agricoltore. L'obiettivo finale sarà raggiunto consentendo agli agricoltori di pianificare la propria attività, il progetto agricolo, la coltura da sviluppare e il mercato dove il prezzo è migliore.",
    descriptionpapers_6:"Inoltre, IPFS mira a incoraggiare e assistere gli agricoltori a gestire la loro azienda in modo più efficace su base giornaliera, agendo come un unico punto di accesso per tutte le loro esigenze di risorse agricole. In quanto tale, l'inclusione di sistemi di gestione dei file di dati come CouchDb o il server MySQL consentirà loro di aggiungere, modificare, eliminare e accedere alle proprie risorse agricole. Inoltre, strumenti integrati come un pianificatore del calendario semplificherebbero l'organizzazione dell'agricoltore informandolo di importanti eventi e attività imminenti. Infine, mira a sfruttare il fatto che un'ampia maggioranza (90%) della popolazione target di agricoltori nell'Africa subsahariana possiede i propri telefoni cellulari.",
    descriptionpapers_7:"Ciò renderà il sistema accessibile online e offline, ottimizzato su desktop, laptop, tablet e telefoni cellulari adottando modelli di progettazione reattivi. Inoltre, l'inclusione di funzionalità e connettività specifiche per i telefoni cellulari mirerà a integrare perfettamente l'IPFS nella vita degli agricoltori e a sostenere efficacemente i loro progressi in agricoltura indipendentemente dal loro livello di istruzione.",
    stackpapers:"Pila",
    tagspapers:"MS Word ",
    showpapers:"Mostrare",
    downloadpapers:"Scaricare",

    //Technologies section
    technologies:'Tecnologie',
    texttechnology:'Ho lavorato con una vasta gamma di tecnologie nel mondo dello sviluppo di app e web seguendo le tre fasi del progetto software. Progettazione, programmazione e sviluppo...',
    experiencetechnology:"Esperienza con",

    //Timeline section
    timelineabout:"A Proposito",
    timelinetext:"Mi chiamo Kouassi Bernard e sono assolutamente convinto che la pazienza sia una via d'oro. Quando ho lasciato la Costa d'Avorio per l'Europa nel 1992, il mio obiettivo principale era ottenere un diploma in informatica, creare software e applicazioni per aiutare le persone e le attività di ogni tipo nello svolgimento più rapido ed efficiente delle loro attività quotidiane. Da allora ho sviluppato una serie di applicazioni mobili personali in diversi campi e settori di attività per il benessere delle popolazioni del Terzo Mondo. Applicazioni in grado di funzionare offline. In breve, la mia ambizione principale è che il cellulare diventi ora un ufficio portatile. Questo è ciò che io chiamo: la tradizione digitale.",

    //Personal Accomplishments section
    accomplishmenttitle:"Realizzazioni personali",
    accomplishmentstock:"Progetti di gestione stock",
    accomplishmentfarming:"Progetti Agricoli",
    accomplishmenteducational:"Progetti Didattici",
    accomplishmentecommerce:"Progetti e-commerce",
};