import React from 'react';
import './pouchprojects';
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
import '../../translations/pouchtranslation';

import { BlogCard, CardInfo, ExternalLinks, ExternalLinksButton, GridContainer, HeaderThree, Hr, Tag, TagList, TitleContent, UtilityList, Img } from './ProjectsStyles';
import { Section, SectionDivider, SectionTitle } from '../../styles/GlobalComponents';
//import Button from '../../styles/GlobalComponents/Button';
//import { projects } from '../../constants/constants';
import Couch from '../../projectimage/couchdb.png';
import Rishta from '../../projectimage/rishta.png';
import Carions from '../../projectimage/carions.png';



function Projects() {
  function anneeDebut(){
    var currentYear = new Date().getFullYear();
    return currentYear;
  }
  const {t} = useTranslation();
  return ( 
    <Section nopadding id='projects'>
      <SectionDivider />
      <SectionTitle main>{t("projet")}</SectionTitle>
      <GridContainer>
        <BlogCard>
          <Img src={Couch} style={{width:"100%", height:"50%"}}/>
          <TitleContent>
            <HeaderThree>{t("titlelive")}</HeaderThree>
            <h5>{anneeDebut()}</h5>
            <Hr />
          </TitleContent>
          <CardInfo id='description_1'>{t("descriptionlive")}</CardInfo>
          <CardInfo id='description_2'>{t("moredescriptionlive")}</CardInfo>
          <div>
            <TitleContent>{t("stack")}</TitleContent>
            <TagList>
              <Tag>{t("tagscouchlive")}</Tag>
              <Tag>{t("tagspouchlive")}</Tag>
              <Tag>{t("tagsreactlive")}</Tag>
            </TagList>
          </div>
          <UtilityList>
            <ExternalLinksButton id='couchbutton'>{t("readmoredesign")}</ExternalLinksButton>
            <ExternalLinksButton id='lesscouchbutton'>{t("heroliremoins")}</ExternalLinksButton>
            <ExternalLinks href="https://www.couchbench.grandbico.com/">{t("demolive")}</ExternalLinks>
          </UtilityList>
        </BlogCard>

        <BlogCard>
          <Img src={Rishta} style={{width:"100%", height:"50%"}}/>
          <TitleContent>
            <HeaderThree>{t("titledesign")}</HeaderThree>
            <h5>{anneeDebut()}</h5>
            <Hr />
          </TitleContent>
          <CardInfo id='descriptdesign'>{t("descriptiondesign")}</CardInfo>
          <CardInfo id='descriptdesignmore'>{t("descriptiondesignmore_1")}</CardInfo>
          <div>
            <TitleContent>{t("stack")}</TitleContent>
            <TagList>
              <Tag>{t("tagscouchlive")}</Tag>
              <Tag>{t("tagspouchlive")}</Tag>
              <Tag>{t("tagsreactlive")}</Tag>
            </TagList>
          </div>
          <UtilityList id='plusdesign'>
            <ExternalLinksButton id='rishtabutton'>{t("readmoredesign")}</ExternalLinksButton>
            <ExternalLinksButton id='lessrishtabutton'>{t("heroliremoins")}</ExternalLinksButton>
            <ExternalLinks href="https://www.essai.grandbico.com/">{t("demolive")}</ExternalLinks>
          </UtilityList>
        </BlogCard>

        <BlogCard>
          <Img src={Rishta} style={{width:"100%", height:"50%"}}/>
          <TitleContent>
            <HeaderThree>{t("titlediagram")}</HeaderThree>
            <h5>{anneeDebut()}</h5>
            <Hr />
          </TitleContent>
          <CardInfo id='descriptdiagram'>{t("descriptiondiagram")}</CardInfo>
          <CardInfo id='descriptdiagrammore'>{t("descriptiondiagram_1")}</CardInfo>
          <div>
            <TitleContent>{t("stack")}</TitleContent>
            <TagList>
              <Tag>{t("tagscouchlive")}</Tag>
              <Tag>{t("tagspouchlive")}</Tag>
              <Tag>{t("tagsreactlive")}</Tag>
            </TagList>
          </div>
          <UtilityList>
            <ExternalLinksButton id='rishtadminbutton'>{t("readmoredesign")}</ExternalLinksButton>
            <ExternalLinksButton id='lessrishtadminbutton'>{t("heroliremoins")}</ExternalLinksButton>
            <ExternalLinks href="https://www.tester.grandbico.com/">{t("demolive")}</ExternalLinks>
          </UtilityList>
        </BlogCard>

        <BlogCard>
          <Img src={Carions} style={{width:"100%", height:"50%"}}/>
          <TitleContent>
            <HeaderThree>{t("titleux")}</HeaderThree>
            <h5>{anneeDebut()}</h5>
            <Hr />
          </TitleContent>
          <CardInfo id='descriptux'>{t("descriptionux")}</CardInfo>
          <CardInfo id='descriptuxmore'>{t("descriptionux_1")}</CardInfo>
          <CardInfo id='descriptuxplus'>{t("descriptionux_2")}</CardInfo>
          <div>
            <TitleContent>{t("stack")}</TitleContent>
            <TagList>
              <Tag>{t("tagspapers")}</Tag>
              {/*<Tag>{t("tagsadobe")}</Tag>*/}
            </TagList>
          </div>
          <UtilityList>
            <ExternalLinksButton id='futurebutton'>{t("readmoredesign")}</ExternalLinksButton>
            <ExternalLinksButton id='lessfuturebutton'>{t("heroliremoins")}</ExternalLinksButton>
          </UtilityList>
        </BlogCard>

        <BlogCard>
          <Img src={Carions} style={{width:"100%", height:"50%"}}/>
          <TitleContent>
            <HeaderThree>{t("titlepapers")}</HeaderThree>
            <h5>{anneeDebut()}</h5>
            <Hr />
          </TitleContent>
          <CardInfo id='descriptpapers'>{t("descriptionpapers")}</CardInfo>
          <CardInfo id='descriptpapers_1'>{t("descriptionpapers_1")}</CardInfo>
          <CardInfo id='descriptpapers_2'>{t("descriptionpapers_2")}</CardInfo>
          <CardInfo id='descriptpapers_3'>{t("descriptionpapers_3")}</CardInfo>
          <CardInfo id='descriptpapers_4'>{t("descriptionpapers_4")}</CardInfo>
          <CardInfo id='descriptpapers_5'>{t("descriptionpapers_5")}</CardInfo>
          <CardInfo id='descriptpapers_6'>{t("descriptionpapers_6")}</CardInfo>
          <CardInfo id='descriptpapers_7'>{t("descriptionpapers_7")}</CardInfo>
          <div>
            <TitleContent>{t("stack")}</TitleContent>
            <TagList>
              <Tag>{t("tagspapers")}</Tag>
            </TagList>
          </div>
          <UtilityList>
            <ExternalLinksButton id='papersbutton'>{t("readmoredesign")}</ExternalLinksButton>
            <ExternalLinksButton id='lesspapersbutton'>{t("heroliremoins")}</ExternalLinksButton>
          </UtilityList>
        </BlogCard>
      </GridContainer>
    </Section>
  );
}

export default Projects;