import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
import '../../translations/pouchtranslation';

import { Section, SectionText, /*SectionTitle*/ } from '../../styles/GlobalComponents';
import Button from '../../styles/GlobalComponents/Button';
import { LeftSection } from '../Hero/HeroStyles';

function ReadMore() {
    function lireMoins() {
        document.getElementById('lire').style.display = 'none';
        document.getElementById('textesection').style.display = 'block';
        document.getElementById('plus').style.display = 'block';
    }

    const {t} = useTranslation();
    return ( 
        <Section id='lire' row nopadding>
            <LeftSection>
                <SectionText>
                    {t("lireplus")}
                </SectionText>
                <Button onClick={lireMoins}>{t("heroliremoins")}</Button>
            </LeftSection>
        </Section>
    );
}

export default ReadMore;