export const TRANSLATIONS_PT = {
    francais:"Francês",
    anglais:"Inglês",
    portugais:"Português",
    italien:"Italiano",

    //Header section
    projet:"Projetos",
    technologie:"Tecnologias",
    apropos:"Sobre",

    //Hero section
    herotexte_1:"Sou um desenvolvedor de aplicativos móveis e web PWA confiável para converter suas ideias de negócios em experiências móveis híbridas e multiplataforma. Ofereço soluções inovadoras e exploro diferentes mercados e segmentos que permitem atingir seu público móvel. ",
    herotexte_2:"Developer full-stack também, concentro todas as minhas habilidades de desenvolvimento no back-end do aplicativo para interagir com o front-end já implementado ou projetado por mim mesmo, se necessário.",
    herolireplus:"Leia Mais",
    heroliremoins:"Leia Menos",
    
    //Read More section
    lireplus:"A razão pela qual minha escolha é pelo PWA, embora minhas habilidades não se limitem exclusivamente a essa nova tecnologia se deve ao fato de permitir ao usuário interagir e realizar ações mesmo em caso de perda da rede de internet. Isso é extremamente benéfico para usuários de aplicativos em países em desenvolvimento, onde o mau funcionamento da rede móvel é comum. PWA é a tecnologia do futuro para esses cenários.",

    //Projects section
    titlelive:'CouchDB Workbench',
    descriptionlive:"O objetivo deste projeto é ajudar usuários técnicos ou não técnicos a consultar e gerenciar dados no CouchDB da mesma forma que fazem com o MySQL Workbench.",
    moredescriptionlive:"Couchbench é um aplicativo web progressivo. Ele fornece uma interface básica para a maioria das funcionalidades, incluindo a capacidade de criar, atualizar, excluir, visualizar e projetar documentos, acesso a definições de configuração e uma interface para iniciar a replicação. O Couchbench é um aplicativo alternativo ao Fauxton como uma interface básica mais simples e intuitiva para desenvolvedores que usam tecnologias CouchDB. Também permite que não desenvolvedores facilitem ações no processamento de suas atividades de pesquisa públicas e privadas. No entanto, os principais usuários do Fauxton descobrirão que a interface do usuário deste aplicativo é 80% semelhante à do fauxton. Apenas uma maneira de se familiarizar rapidamente com ele. O aplicativo inclui documentação sobre como usá-lo.",
    stack:"Pilha",
    tagscouchlive:"CouchDB",
    tagspouchlive:"PouchDB",
    tagsreactlive:"React",
    demolive:"Demo",
    installive:"Instalar",

    titledesign: 'Rishta Users',
    descriptiondesign: "Rishta é um aplicativo da web progressivo de namoro muçulmano inteligente e inteligente. Ele foi construído com base nas necessidades do cliente ",
    descriptiondesignmore_1:"e também fornece uma interface básica usando tecnologias CouchDB. O principal objetivo do Rishta é conectar milhões de muçulmanos que vivem no Reino Unido para se casar através deste aplicativo, ajudando-os a encontrar sua alma gêmea ou melhor amizade, vivendo ou fazendo coisas juntos por muito tempo. Apenas para aqueles que conhecem o costume muçulmano de lidar com o processo de casamento, Rishta os ajuda ao longo de todo o processo de casamento. Um matchmaker amigável em tempo real que dá uma ideia de quanta mudança um solteiro pode combinar com sua alma gêmea com base em suas necessidades.",
    stackdesign:"Stack",
    tagsworddesign:" MS Word",
    readmoredesign:"Leia Mais",
    downloaddesign:"Download",

    titlediagram: 'Rishta Admin',
    descriptiondiagram: "Um resumo geral do sistema de administração que fornece uma visão completa de como rishta será gerenciado por seu proprietário.",
    descriptiondiagram_1:"No entanto, este aplicativo de administração é usado de forma privada. É óbvio que todo negócio precisa ser gerenciado de forma consistente e eficiente que envolva todos os empresários para conseguir se eles realmente precisam que seus negócios cresçam. O Rishta Admin é construído usando o mesmo aplicativo progressivo e tecnologias couchDB.",
    stackdiagram:"Stack",
    tagsdrawiodiagram:"Draw.io ",
    tagsterratrustdiagram:"Terrastruct",
    showdiagram:"Mostrar",
    downloaddiagram:"Download",

    titleux: 'Projetos futuros',
    descriptionux: "Ideias para projetos futuros não faltam e estão sempre cheias para quem é apaixonado pelo desenvolvimento de novos aplicativos e softwares.",
    descriptionux_1:"1. Uma plataforma de interconexão e intercâmbio de vendedores e compradores de 15 países diferentes da sub-região da África Ocidental para uma população estimada em mais de 350 milhões de habitantes onde os sistemas de comercialização e troca de vários produtos estagnaram desde 1960.",
    descriptionux_2:"2. Sistema integrado de agricultura de precisão será um conjunto de técnicas e práticas que limitam racionalmente o uso de insumos e água em pequenas e médias parcelas agrícolas. Este sistema levará em conta a heterogeneidade das parcelas para ajustar quantitativamente, no tempo e no espaço, insumos e água para melhorar o equilíbrio econômico, agronômico e ambiental das pequenas e médias propriedades. Terá como objetivo adaptar a intervenção do agricultor na parcela, utilizando dados recolhidos em fichas fornecidas e devidamente preenchidas, com base em observações simples e testes realizados no campo. Esses dados serão então processados ​​e armazenados em um servidor de banco de dados centralizado para permitir que o agricultor visualize e analise as operações realizadas ou a serem realizadas em sua fazenda. O sistema terá como objetivo adotar uma abordagem mais centrada no agricultor, proporcionando um ambiente agrícola adequado às preferências dos agricultores. O objetivo final era ser alcançado permitindo que os agricultores planejassem seus negócios, seu projeto agrícola, a safra a crescer e o mercado onde o preço é melhor.",
    stackux:"Stack",
    tagsfigma:"Figma",
    tagsadobe:"Adobe",
    showux:"Mostrar",
    downloadux:"Download",

    titlepapers: 'Documentação',
    descriptionpapers: "Por que uma aplicação de Agricultura de Precisão é importante em países do Terceiro Mundo?",
    descriptionpapers_1:"A agricultura de precisão permite compreender e analisar melhor as necessidades fisiológicas das culturas e desenvolver ferramentas de apoio à decisão dos agricultores. Os agricultores podem usá-lo para caracterizar as plantas com mais facilidade e rapidez, reduzindo o trabalho em tarefas muitas vezes tediosas. Um software desenvolvido para esse fim utilizará os dados massivos coletados ou gerados para desenvolver modelos e melhorar a eficiência da agricultura de precisão.",
    descriptionpapers_2:"Para organizações agrícolas profissionais, essa é uma maneira de fornecer conselhos mais precisos, produzindo mapas de modulação de parcelas para várias operações de plantio, processamento e fertilização para ajudar os agricultores a interpretar seus dados. A questão da veracidade desses dados é um problema devido à falta de regulamentação. No contexto da agroecologia, a agricultura de precisão se posiciona em uma abordagem tecnológica que exige hardware e software caros na fazenda e infraestruturas coletivas para as quais é difícil avaliar o investimento e os custos operacionais (satélites de posicionamento GPS, servidores centralizados para processamento massivo de dados). A questão de sua eficiência além das grandes fazendas permanece.",
    descriptionpapers_3:"Um Sistema Integrado de Agricultura de Precisão (IPFS) expandirá os limites do gerenciamento e desenvolvimento de pequenas e médias fazendas de cultivo de alimentos em países do terceiro mundo, fornecendo ferramentas de software para apoiar e melhorar as atividades de horticultura comercial.",
    descriptionpapers_4:"Extensas pesquisas sobre muitos softwares de agricultura de precisão atualmente no mercado destacam que esses sistemas, além de seu preço extremamente caro, são complexos do ponto de vista do mecanismo e das tecnologias utilizadas na implantação. Os procedimentos de interação e as interfaces de usuário usam palavras mais técnicas que um simples agricultor não poderia saber nem entender seus significados. De fato, o principal objetivo de um IPFS é simplificar o uso de software de agricultura de precisão em sua forma básica, incluindo os mesmos recursos e aspectos de um sistema de software complexo. Caso contrário, qual seria o real propósito do desenvolvimento de software? Simplifique o que é complexo para facilitar o dia a dia e não o contrário.",
    descriptionpapers_5:"O IPFS é um conjunto de técnicas e práticas que limitam racionalmente o uso de insumos e água em pequenas e médias parcelas agrícolas. Considera a heterogeneidade das parcelas para ajustar os insumos e a água quantitativamente, no tempo e no espaço, para melhorar o equilíbrio econômico, agronômico e ambiental das pequenas e médias propriedades. Pretende adaptar a intervenção do agricultor na parcela, utilizando dados recolhidos em fichas fornecidas e devidamente preenchidas, com base em simples observações e testes realizados no campo. Esses dados são então processados e armazenados em um servidor de banco de dados centralizado para permitir que o agricultor visualize e analise as operações realizadas ou a serem realizadas em sua fazenda. O IPFS visa adotar uma abordagem mais centrada no agricultor, fornecendo um ambiente agrícola adaptado às preferências do agricultor. O objetivo final será alcançado ao permitir que os agricultores planejem seu negócio, projeto agrícola, a cultura a ser desenvolvida e o mercado onde o preço é melhor.",
    descriptionpapers_6:"Além disso, o IPFS visa incentivar e ajudar os agricultores a gerenciar suas fazendas de forma mais eficaz diariamente, atuando como um ponto de acesso único para todas as suas necessidades de recursos agrícolas. Assim, a inclusão de sistemas de gerenciamento de arquivos de dados como CouchDb ou servidor MySQL permitirá adicionar, editar, excluir e acessar seus recursos agrícolas. Além disso, ferramentas integradas, como um planejador de calendário, facilitariam a organização do agricultor, informando-o sobre eventos e tarefas importantes. Por último, pretende tirar partido do facto de uma grande maioria (90%) da população-alvo de agricultores na África Subsariana possuir os seus telemóveis.",
    descriptionpapers_7:"Isso tornará o sistema acessível online e offline, otimizado em desktops, laptops, tablets e celulares, adotando padrões de design reativos. Além disso, a inclusão de recursos e conectividade específicos de telefones celulares visará integrar perfeitamente o IPFS na vida dos agricultores e apoiar efetivamente seu progresso na agricultura, independentemente de seu nível de educação.",
    stackpapers:"Stack",
    tagspapers:"MS Word",
    showpapers:"Mostrar",
    downloadpapers:"Download",

    //Technologies section
    technologies:'Tecnologias',
    texttechnology:'Trabalhei com diversas tecnologias no mundo do desenvolvimento web e de aplicativos seguindo as três etapas do projeto de software. Projeto, codificação e desenvolvimento...',
    experiencetechnology:"Experiência com",

    //Timeline section
    timelineabout:"Sobre",
    timelinetext:"Meu nome é Kouassi Bernard e estou absolutamente convencido de que a paciência é um caminho de ouro. Quando parti da Costa do Marfim para a Europa em 1992, o meu principal objetivo era obter um diploma em informática, criar software e aplicações para ajudar pessoas e atividades de todo o tipo na realização mais rápida e eficiente das suas tarefas diárias. Desde então, desenvolvi uma série de aplicativos móveis pessoais em diversos campos e setores de atividade para o bem-estar das populações do Terceiro Mundo. Aplicativos capazes de operar Offline. Em suma, a minha principal ambição é que o telemóvel se torne agora um escritório portátil. Isso é o que eu chamo: A tradição digital.",

    //Personal Accomplishments section
    accomplishmenttitle:"Realizações pessoais",
    accomplishmentstock:"Projetos de Gestão de Estoque",
    accomplishmentfarming:"Projetos agrícolas",
    accomplishmenteducational:"Projetos Educacionais",
    accomplishmentecommerce:"Projetos de E-Commerce",
};