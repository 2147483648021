export const TRANSLATIONS_FR = {
    francais:"Français",
    anglais:"Anglais",
    portugais:"Portugais",
    italien:"Italien",

    //Header section
    projet:"Projets",
    technologie:"Technologies",
    apropos:"Concernant",

    //Hero section
    herotexte_1:"Je suis un développeur d'applications Web et mobiles PWA de confiance pour convertir vos idées commerciales en expériences mobiles hybrides et multiplateformes. Je propose des solutions innovantes et j'explore différents marchés et segments qui vous permettent de cibler votre audience mobile. ",
    herotexte_2:"Egalement développeur full-stack, je concentre toutes mes compétences en développement sur le back-end de l'application pour interagir avec le front-end déjà implémenté ou conçu par moi-même si besoin.",
    herolireplus:"Lire la suite",
    heroliremoins:"Lire Moins",

    //Read More section
    lireplus:"Le motif pour lequel mon choix se porte sur la PWA, bien que mes compétences ne se limitent pas exclusivement à cette nouvelle technologie est dû au fait qu'elle permet à l'utilisateur d'interagir et exécuter des actions même en cas de perte du réseau internet. Ceci est extrêmement bénéfique pour les utilisateurs d'applications dans les pays en voie de développement où les dysfonctionnements du réseau mobile sont monnaie courante. PWA est la technologie du futur pour ces cas de figure.",

    //Projects section
    titlelive: 'CouchDB Workbench',
    descriptionlive: "Le but de ce projet est d'aider les utilisateurs techniques ou non techniques à interroger et à gérer des données dans CouchDB de la même manière qu'ils le font avec MySQL Workbench.",
    moredescriptionlive:"Couchbench est une application Web progressive. Il fournit une interface de base à la majorité des fonctionnalités, notamment la possibilité de créer, mettre à jour, supprimer, afficher et concevoir des documents, l'accès aux paramètres de configuration et une interface pour lancer la réplication. Couchbench est une application alternative à Fauxton en tant qu'interface de base plus simple et plus intuitive pour les développeurs utilisant les technologies CouchDB. Il permet également aux non-développeurs de faciliter les actions dans le traitement de leurs activités d'enquêtes publiques et privées. Cependant, les utilisateurs grand public de Fauxton constateront que l'interface utilisateur de cette application est similaire à 80 % à celle de Fauxton. Juste une façon de se familiariser rapidement avec elle. L'application comprend une documentation sur la façon de l'utiliser.",
    stack:"Pile",
    tagscouchlive:"CouchDB  ",
    tagspouchlive:"PouchDB  ",
    tagsreactlive:"React",
    demolive:"Demo",
    installive:"Installer",

    titledesign: 'Rishta Users',
    descriptiondesign: "Rishta est une application Web progressive intelligente de rencontres musulmanes. Il a été construit en fonction des besoins du client ",
    descriptiondesignmore_1:"et fournit également une interface de base utilisant les technologies CouchDB. L'objectif principal de Rishta est de connecter des millions de musulmans vivant au Royaume-Uni pour se marier grâce à cette application en les aidant à trouver leur âme sœur ou leur meilleure amitié, à vivre ou à faire des choses ensemble pendant longtemps. Juste pour ceux qui sont conscients de la coutume musulmane concernant le processus de mariage, Rishta les aide tout au long du processus de mariage. Un entremetteur amical en temps réel qui donne une idée de la quantité de changement qu'un célibataire peut faire correspondre son âme sœur en fonction de ses besoins.",
    stackdesign:"Stack",
    tagsworddesign:" MS Word ",
    readmoredesign:"Lire Suite",
    downloaddesign:"Télécharger",

    titlediagram: 'Rishta Admin',
    descriptiondiagram: "Un aperçu général d'un système d'administration abstrait qui fournit une vue complète de la façon dont rishta sera géré par son propriétaire.",
    descriptiondiagram_1:"Cependant, cette application d'administration est utilisée en privé. Il est évident que chaque entreprise doit être gérée de manière cohérente et efficace, ce qui engage tous les hommes d'affaires à obtenir s'ils ont vraiment besoin que leur entreprise se développe. Rishta Admin est construit en utilisant les mêmes technologies progressives app et couchDB.",
    stackdiagram:"Stack",
    tagsdrawiodiagram:"Draw.io ",
    tagsterratrustdiagram:"Terrastruct ",
    showdiagram:"Montrer",
    downloaddiagram:"Télécharger",

    titleux: 'Futurs Projets',
    descriptionux: "Les idées de futurs projets foisonnent et ne desemplissent pas pour celui qui est un passioné du développement de nouvelles applications et logiciels.",
    descriptionux_1:"1. Une platforme d'interconnexion et d'échange de vendeurs et acheteurs de 15 pays différents de la sous-région Ouest-Africaine pour une population estimée à plus de 350 milions d'habitants où les systèmes de commercialisations et d'échanges de divers produits stagnent depuis 1960.",
    descriptionux_2:"2. Système intégré d'agriculture de précision sera un ensemble de techniques et de pratiques limitant rationnellement l'utilisation des intrants et de l'eau sur les petites et moyennes parcelles agricoles. Ce système prendra en compte l'hétérogénéité des parcelles pour ajuster quantitativement, dans le temps et dans l'espace les intrants et l'eau pour améliorer l'équilibre économique, agronomique et environnemental des petites et moyennes exploitations. Il visera à adapter l'intervention de l'agriculteur sur la parcelle en utilisant des données recueillies sur des fiches fournies et dûment complétées, basées sur des observations simples et des tests effectués sur le terrain. Ces données seront ensuite traitées et stockées sur un serveur de base de données centralisé pour permettre à l'agriculteur de visualiser et d'analyser les opérations réalisées ou à réaliser sur son exploitation. Le système visera à adopter une approche plus centrée sur les agriculteurs en fournissant un environnement agricole adapté aux préférences des agriculteurs. L'objectif final devait être atteint en permettant aux agriculteurs de planifier leur entreprise, leur projet agricole, la culture à développer et le marché où le prix est meilleur.",
    stackux:"Stack",
    tagsfigma:"Figma ",
    tagsadobe:"Adobe ",
    showux:"Montrer",
    downloadux:"Télécharger",

    titlepapers: 'Documentations',
    descriptionpapers: "Pourquoi une application d'agriculture de précision est-elle importante dans les pays du tiers monde ?",
    descriptionpapers_1:"L'agriculture de précision permet de mieux comprendre et analyser les besoins physiologiques des cultures et de développer des outils d'aide à la décision pour les agriculteurs. Les agriculteurs peuvent l'utiliser pour caractériser les plantes plus facilement et plus rapidement en réduisant la main-d'œuvre sur des tâches souvent fastidieuses. Un logiciel développé à cet effet utilisera les données massives collectées ou générées pour développer des modèles et améliorer l'efficacité de l'agriculture de précision.",
    descriptionpapers_2:"Pour les organisations professionnelles agricoles, c'est un moyen d'apporter un conseil plus précis en réalisant des cartes de modulation parcellaire des différentes opérations de plantation, de transformation et de fertilisation pour aider les agriculteurs dans l'interprétation de leurs données. La question de la véracité de ces données est un problème en raison d'un manque de réglementation. Dans le cadre de l'agroécologie, l'agriculture de précision se positionne dans une approche technologique nécessitant du matériel et des logiciels coûteux à la ferme, et des infrastructures collectives dont il est difficile d'évaluer les coûts d'investissement et de fonctionnement (satellites de positionnement GPS, serveurs centralisés pour le traitement massif de données) . Reste la question de son efficacité au-delà des grandes exploitations.",
    descriptionpapers_3:"Un système intégré d'agriculture de précision (IPFS) élargira les limites de la gestion et du développement des petites et moyennes exploitations agricoles vivrières dans les pays du tiers monde en fournissant des outils logiciels pour soutenir et améliorer les activités de maraîchage.",
    descriptionpapers_4:"Des recherches approfondies sur de nombreux logiciels d'agriculture de précision actuellement sur le marché mettent en évidence que ces systèmes, en dehors de leur prix extrêmement élevé, sont complexes du point de vue du mécanisme et des technologies utilisées pour le déploiement. Les procédures d'interaction et les interfaces utilisateur utilisent des mots plus techniques qu'un simple agriculteur ne pourrait même pas comprendre leur signification. En effet, l'objectif principal d'un IPFS est de simplifier l'utilisation d'un logiciel d'agriculture de précision dans sa forme de base, incluant les mêmes fonctionnalités et aspects d'un système logiciel complexe. Sinon, quel serait le véritable objectif du développement logiciel ? Simplifier ce qui est complexe pour faciliter le quotidien et non l'inverse.",
    descriptionpapers_5:"L'IPFS est un ensemble de techniques et de pratiques qui limitent rationnellement l'utilisation des intrants et de l'eau dans les petites et moyennes parcelles agricoles. Il prend en compte l'hétérogénéité des parcelles pour ajuster quantitativement, dans le temps et dans l'espace les intrants et l'eau pour améliorer l'équilibre économique, agronomique et environnemental des petites et moyennes exploitations. Il vise à adapter l'intervention de l'agriculteur sur la parcelle en utilisant des données recueillies sur des fiches fournies et dûment complétées, basées sur des observations simples et des tests effectués sur le terrain. Ces données sont ensuite traitées et stockées sur un serveur de base de données centralisé pour permettre à l'agriculteur de visualiser et d'analyser les opérations réalisées ou à réaliser sur son exploitation. IPFS vise à adopter une approche plus centrée sur les agriculteurs en fournissant un environnement agricole adapté aux préférences des agriculteurs. L'objectif final sera atteint en permettant aux agriculteurs de planifier leur activité, leur projet agricole, la culture à développer et le marché où le prix est meilleur.",
    descriptionpapers_6:"Par ailleurs, IPFS vise à encourager et accompagner les agriculteurs à mieux gérer leur exploitation au quotidien en agissant comme un point d'accès unique pour l'ensemble de leurs besoins en ressources agricoles. Ainsi, l'inclusion de systèmes de gestion de fichiers de données tels que CouchDb ou le serveur MySQL leur permettra d'ajouter, de modifier, de supprimer et d'accéder à leurs ressources agricoles. De plus, des outils intégrés tels qu'un planificateur de calendrier faciliteraient l'organisation des agriculteurs en les informant des événements et des tâches importants à venir. Enfin, il vise à tirer profit du fait qu'une grande majorité (90%) de la population cible des agriculteurs en Afrique sub-saharienne possède son téléphone portable.",
    descriptionpapers_7:"Cela rendra le système accessible en ligne et hors ligne, optimisé sur les ordinateurs de bureau, les ordinateurs portables, les tablettes et les téléphones mobiles en adoptant des modèles de conception réactifs. En outre, l'inclusion de fonctionnalités et de connectivité spécifiques au téléphone mobile visera à intégrer de manière transparente l'IPFS dans la vie des agriculteurs et à soutenir efficacement leurs progrès dans l'agriculture, quel que soit leur niveau d'éducation.",
    stackpapers:"Stack",
    tagspapers:"MS Word ",
    showpapers:"Montrer",
    downloadpapers:"Télécharger",

    //Technologies section
    technologies:'Technologies',
    texttechnology:"J'ai travaillé avec une gamme de technologies dans le monde du développement Web et d'applications en suivant les trois étapes d'un projet logiciel. Conception, programmation et développement...",
    experiencetechnology:"Expérience avec",

    //Timeline section
    timelineabout:"À Propos",
    timelinetext:"Je me nomme Kouassi Bernard et je suis absolument convaincu que la patience est un chemin d'or. Lorsque je quittais la Côte d'Ivoire pour l'Europe en 1992, mon objectif principal était  d'obtenir un diplôme en informatique, créer des logiciels et apllications pour aider les personnes et activités de tous genres dans l'accomplissement plus rapide et efficient de leurs tâches quotidiennes. Dès lors, j'ai développé une série d'applications mobiles personnelles dans plusieurs domaines et secteurs d'activités pour le bien être des populations du tier monde. Des applications capables de fonctionner Offline. En somme, mon ambition principale est que le téléphone mobile devienne désormais un bureau portable. C'est ce que j'appelle: La tradition digitale.",

    //Personal Accomplishments section
    accomplishmenttitle:"Réalisations personnelles",
    accomplishmentstock:"Projets de gestion des stocks",
    accomplishmentfarming:"Projets agricoles",
    accomplishmenteducational:"Projets éducatifs",
    accomplishmentecommerce:"Projets E-Commerce",
};