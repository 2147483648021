window.addEventListener('load', () => {
    //couchbench
    document.getElementById('description_1').style.display = 'block';
    document.getElementById('description_2').style.display = 'none';
    document.getElementById('couchbutton').style.display = 'block';
    document.getElementById('lesscouchbutton').style.display = 'none';
    //rishta
    document.getElementById('descriptdesign').style.display = 'block';
    document.getElementById('descriptdesignmore').style.display = 'none';
    document.getElementById('rishtabutton').style.display = 'block';
    document.getElementById('lessrishtabutton').style.display = 'none';
    //rishta admin
    document.getElementById('descriptdiagram').style.display = 'block';
    document.getElementById('descriptdiagrammore').style.display = 'none';
    document.getElementById('rishtadminbutton').style.display = 'block';
    document.getElementById('lessrishtadminbutton').style.display = 'none';
    //future project
    document.getElementById('descriptux').style.display = 'block';
    document.getElementById('descriptuxmore').style.display = 'none';
    document.getElementById('descriptuxplus').style.display = 'none';
    document.getElementById('futurebutton').style.display = 'block';
    document.getElementById('lessfuturebutton').style.display = 'none';
    //papers
    document.getElementById('descriptpapers').style.display = 'block';
    document.getElementById('descriptpapers_1').style.display = 'block';
    document.getElementById('descriptpapers_2').style.display = 'none';
    document.getElementById('descriptpapers_3').style.display = 'none';
    document.getElementById('descriptpapers_4').style.display = 'none';
    document.getElementById('descriptpapers_5').style.display = 'none';
    document.getElementById('descriptpapers_6').style.display = 'none';
    document.getElementById('descriptpapers_7').style.display = 'none';
    document.getElementById('papersbutton').style.display = 'block';
    document.getElementById('lesspapersbutton').style.display = 'none';

    //xxxxx couchbench handler
    document.getElementById('couchbutton').addEventListener('click', couchbuttonChange);
    async function couchbuttonChange(e){
        e.preventDefault();

        document.getElementById('description_1').style.display = 'none';
        document.getElementById('description_2').style.display = 'block';
        document.getElementById('couchbutton').style.display = 'none';
        document.getElementById('lesscouchbutton').style.display = 'block';
    }

    document.getElementById('lesscouchbutton').addEventListener('click', lesscouchbuttonChange);
    async function lesscouchbuttonChange(e){
        e.preventDefault();

        document.getElementById('description_1').style.display = 'block';
        document.getElementById('description_2').style.display = 'none';
        document.getElementById('couchbutton').style.display = 'block';
        document.getElementById('lesscouchbutton').style.display = 'none';
    }

    //xxxxx rishta handler
    document.getElementById('rishtabutton').addEventListener('click', rishtabuttonChange);
    async function rishtabuttonChange(e){
        e.preventDefault();

        document.getElementById('descriptdesign').style.display = 'none';
        document.getElementById('descriptdesignmore').style.display = 'block';
        document.getElementById('rishtabutton').style.display = 'none';
        document.getElementById('lessrishtabutton').style.display = 'block';
    }

    document.getElementById('lessrishtabutton').addEventListener('click', lessrishtabuttonChange);
    async function lessrishtabuttonChange(e){
        e.preventDefault();

        document.getElementById('descriptdesign').style.display = 'block';
        document.getElementById('descriptdesignmore').style.display = 'none';
        document.getElementById('rishtabutton').style.display = 'block';
        document.getElementById('lessrishtabutton').style.display = 'none';
    }

    //xxxxx rishta admin handler
    document.getElementById('rishtadminbutton').addEventListener('click', rishtadminbuttonChange);
    async function rishtadminbuttonChange(e){
        e.preventDefault();

        document.getElementById('descriptdiagram').style.display = 'none';
        document.getElementById('descriptdiagrammore').style.display = 'block';
        document.getElementById('rishtadminbutton').style.display = 'none';
        document.getElementById('lessrishtadminbutton').style.display = 'block';
    }

    document.getElementById('lessrishtadminbutton').addEventListener('click', lessrishtadminbuttonChange);
    async function lessrishtadminbuttonChange(e){
        e.preventDefault();

        document.getElementById('descriptdiagram').style.display = 'block';
        document.getElementById('descriptdiagrammore').style.display = 'none';
        document.getElementById('rishtadminbutton').style.display = 'block';
        document.getElementById('lessrishtadminbutton').style.display = 'none';
    }

    //xxxxx future projects handler
    document.getElementById('futurebutton').addEventListener('click', futurebuttonChange);
    async function futurebuttonChange(e){
        e.preventDefault();

        document.getElementById('descriptux').style.display = 'none';
        document.getElementById('descriptuxmore').style.display = 'block';
        document.getElementById('descriptuxplus').style.display = 'block';
        document.getElementById('futurebutton').style.display = 'none';
        document.getElementById('lessfuturebutton').style.display = 'block';
    }

    document.getElementById('lessfuturebutton').addEventListener('click', lessfuturebuttonChange);
    async function lessfuturebuttonChange(e){
        e.preventDefault();

        document.getElementById('descriptux').style.display = 'block';
        document.getElementById('descriptuxmore').style.display = 'none';
        document.getElementById('descriptuxplus').style.display = 'none';
        document.getElementById('futurebutton').style.display = 'block';
        document.getElementById('lessfuturebutton').style.display = 'none';
    }

    //xxxxx future papers handler
    document.getElementById('papersbutton').addEventListener('click', papersbuttonChange);
    async function papersbuttonChange(e){
        e.preventDefault();

        document.getElementById('descriptpapers').style.display = 'none';
        document.getElementById('descriptpapers_1').style.display = 'none';
        document.getElementById('descriptpapers_2').style.display = 'block';
        document.getElementById('descriptpapers_3').style.display = 'block';
        document.getElementById('descriptpapers_4').style.display = 'block';
        document.getElementById('descriptpapers_5').style.display = 'block';
        document.getElementById('descriptpapers_6').style.display = 'block';
        document.getElementById('descriptpapers_7').style.display = 'block';
        document.getElementById('papersbutton').style.display = 'none';
        document.getElementById('lesspapersbutton').style.display = 'block';
    }

    document.getElementById('lesspapersbutton').addEventListener('click', lesspapersbuttonChange);
    async function lesspapersbuttonChange(e){
        e.preventDefault();

        document.getElementById('descriptpapers').style.display = 'block';
        document.getElementById('descriptpapers_1').style.display = 'block';
        document.getElementById('descriptpapers_2').style.display = 'none';
        document.getElementById('descriptpapers_3').style.display = 'none';
        document.getElementById('descriptpapers_4').style.display = 'none';
        document.getElementById('descriptpapers_5').style.display = 'none';
        document.getElementById('descriptpapers_6').style.display = 'none';
        document.getElementById('descriptpapers_7').style.display = 'none';
        document.getElementById('papersbutton').style.display = 'block';
        document.getElementById('lesspapersbutton').style.display = 'none';
    }
});