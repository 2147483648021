import React from 'react';
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
import '../../translations/pouchtranslation';

import { DiFirebase, DiReact, DiZend } from 'react-icons/di';
import { Section, SectionDivider, SectionText, SectionTitle } from '../../styles/GlobalComponents';
import { List, ListContainer, ListItem, ListParagraph, ListTitle } from './TechnologiesStyles';

function Technologies() {
  const {t} = useTranslation();
  return ( 
    <Section id='tech'>
      <SectionDivider />
      <br/>
      <SectionTitle>{t("technologies")}</SectionTitle>
      <SectionText>{t("texttechnology")}</SectionText>
      <List>
        <ListItem>
          <DiReact size="3rem" />
          <ListContainer>
            <ListTitle>Front-End</ListTitle>
            <ListParagraph>
              {t("experiencetechnology")} <br/>
              React.js and PHP
            </ListParagraph>
          </ListContainer>
        </ListItem>

        <ListItem>
          <DiFirebase size="3rem" />
          <ListContainer>
            <ListTitle>Back-End</ListTitle>
            <ListParagraph>
              {t("experiencetechnology")} <br/>
              Node.js, MySQL and PouchDB-CouchDB
            </ListParagraph>
          </ListContainer>
        </ListItem>

        <ListItem>
          <DiZend size="3rem" />
          <ListContainer>
            <ListTitle>UI/UX</ListTitle>
            <ListParagraph>
              {t("experiencetechnology")} <br/>
              Figma and Adobe Creative
            </ListParagraph>
          </ListContainer>
        </ListItem>
      </List>
    </Section>
  );
}

export default Technologies;
