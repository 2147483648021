import React from 'react';
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
import '../../translations/pouchtranslation';

import { Section, SectionText, SectionTitle } from '../../styles/GlobalComponents';
import Button from '../../styles/GlobalComponents/Button';
import { TranslateContainer, LeftSection, ButtonLinks } from './HeroStyles';

function Hero() {
  function lirePlus() {
    document.getElementById('lire').style.display = 'block';
    document.getElementById('textesection').style.display = 'none';
    document.getElementById('plus').style.display = 'none';
  }

  const {t} = useTranslation();
  return ( 
    <Section row nopadding>
      <LeftSection>
        <TranslateContainer>
          <ButtonLinks id='fr' >{t("francais")}</ButtonLinks>
          <ButtonLinks id='pt' >{t("portugais")}</ButtonLinks>
          <ButtonLinks id='en' >{t("anglais")}</ButtonLinks>
          <ButtonLinks id='it' >{t("italien")}</ButtonLinks>
        </TranslateContainer>
        <SectionTitle main center>
          PWA Back-end<br/> Web/Mobile Developer
        </SectionTitle>
        <SectionText id='textesection'>
          {t("herotexte_1")}
          {t("herotexte_2")}
        </SectionText>
        <div id='plus'>
          <Button onClick={lirePlus}>{t("herolireplus")}</Button>
        </div>
      </LeftSection>
    </Section>
  );
}

export default Hero;